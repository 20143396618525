@import "../../../node_modules/@adyen/adyen-web/dist/adyen.css";
@import "../../../packages/adyen/web/adyen.css";

@font-face {
    font-family: "GT-Haptik-Black";
    font-weight: 100;
    font-style: normal;
    src: url(./static/fonts/GT-Haptik-Black.woff) format("opentype");
}

@font-face {
    font-family: "GT-Haptik-Black-Oblique";
    font-weight: 100;
    font-style: normal;
    src: url(./static/fonts/GT-Haptik-Black-Oblique.woff) format("opentype");
}

@font-face {
    font-family: "GT-Haptik-Black-Rotalic";
    font-weight: 100;
    font-style: normal;
    src: url(./static/fonts/GT-Haptik-Black-Rotalic.woff) format("opentype");
}

@font-face {
    font-family: "GT-Haptik-Regular";
    font-weight: 100;
    font-style: normal;
    src: url(./static/fonts/GT-Haptik-Regular.woff) format("opentype");
}

@font-face {
    font-family: "GT-Haptik-Medium";
    font-weight: 100;
    font-style: normal;
    src: url(./static/fonts/GT-Haptik-Medium.woff) format("opentype");
}

@font-face {
    font-family: "GT-Haptik-Regular-Oblique";
    font-weight: 100;
    font-style: normal;
    src: url(./static/fonts/GT-Haptik-Regular-Oblique.woff) format("opentype");
}

@font-face {
    font-family: "GT-Haptik-Regular-Oblique";
    font-weight: 100;
    font-style: normal;
    src: url(./static/fonts/GT-Haptik-Regular-Oblique.woff) format("opentype");
}

html,
body {
    max-width: 100%;
}

body {
    font-family: "GT-Haptik-Regular", sans-serif;
    color: #2a134e;
    background-color: #f3f3f6;
    line-height: 1.6;
    font-size: 16px;

    @media (min-width: 480px) {
        font-size: 18px;
    }
}

.bold {
    font-family: "GT-Haptik-Medium", sans-serif;
}
.black {
    font-family: "GT-Haptik-Black", sans-serif;
}

b {
    font-family: "GT-Haptik-Medium", sans-serif;
}

strong {
    font-family: "GT-Haptik-Medium", sans-serif;
}

h1,
h2 {
    font-family: "GT-Haptik-Black", sans-serif;
    margin-bottom: 32px;
    margin-top: 48px;
    font-size: 48px;
}

h3 {
    font-family: "GT-Haptik-Medium", sans-serif;
    color: #2a134e;
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 30px;
}

h4 {
    font-family: "GT-Haptik-Black", sans-serif;
    font-size: 18px;
}

@media screen and (max-width: 479px) {
    h1,
    h2 {
        font-size: 32px;
    }
}

p {
    margin-bottom: 32px;
}

p:last-of-type {
    margin-bottom: 0;
}

.payoff {
    font-size: 32px;
}

.min-width-xs {
    display: none;
}
@media screen and (min-width: 576px) {
    .min-width-xs {
        display: flex;
    }
}
.min-width-md {
    display: none;
}
@media screen and (min-width: 768px) {
    .min-width-md {
        display: flex;
    }
}

.max-width-md {
    display: flex;
}
@media screen and (min-width: 768px) {
    .max-width-md {
        display: none;
    }
}

.min-width-lg {
    display: none;
}
@media screen and (min-width: 992px) {
    .min-width-lg {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .payoff {
        font-size: 24px;
    }
}

a {
    text-decoration: none;
    color: #ff1aba;
}

.image-and-text {
    margin-bottom: 80px;
}
.image-and-text-min-height {
    min-height: 0px;
}

.fuzzy-image {
    transform: scale(1.1) translateX(-15%);
}

@media screen and (min-width: 1140px) {
    .image-and-text {
        margin-bottom: 220px;
    }
}

@media screen and (min-width: 768px) {
    .fuzzy-image {
        transform: scale(1.5) translateX(-25%) translateY(15%);
    }
    .image-and-text-min-height {
        min-height: 440px;
    }
}

.adyen-checkout__button--pay {
    background-color: #ff1aba;
}

.adyen-checkout__button--pay:hover {
    background-color: #ff3dc4;
}

.adyen-checkout__status--success {
    width: 512px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
